<template>
  <a-modal
    v-model="visible"
    width="800px"
    title="收款信息"
    ok-text="确认"
    cancel-text="取消"
    :maskClosable="false"
    :keyboard="false"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleOk"
    @cancel="handleCancel">
    <div>
      <a-descriptions :column="5">
        <a-descriptions-item>
          <a-statistic title="商品数量" :value="data.itemCount || 0"/>
        </a-descriptions-item>
        <a-descriptions-item>
          <a-statistic title="应收（RM）" :precision="2" :value="data.amountPay || 0"/>
        </a-descriptions-item>
        <a-descriptions-item>
          <a-statistic title="收银（RM）" :precision="2" :value="amountReceipt"/>
        </a-descriptions-item>
        <a-descriptions-item>
          <a-statistic title="待收（RM）" :precision="2" :value="amountUnpaid"/>
        </a-descriptions-item>
        <a-descriptions-item>
          <a-statistic title="找零（RM）" :precision="2" :value="amountChange"/>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <a-divider orientation="left"></a-divider>
    <a-form-model
      ref="form"
      :model="form"
      :label-col="{span:6}"
      :wrapper-col="{span:18}">
      <a-row>
        <a-col>
          <a-form-model-item label="收款方式" prop="type" :label-col="{span:4}" :wrapper-col="{span:18}">
            <a-radio-group v-model="payType" button-style="solid">
              <template v-for="item in paymentMap">
                <a-radio-button :value="item[0]" :key="item[0]" :disabled="item[0] === 3 && !data.memberId"
                                v-if="!(data.orderType ===10 && item[0] === 3)">
                  {{item[1]}}
                </a-radio-button>
              </template>
            </a-radio-group>
            <div class="mt-20" v-show="payType === 5">
              <a-row>
                <a-col :span="12">
                  <a-form-model-item label="聚合支付" prop="uniPay">
                    <a-input-number
                      size="large"
                      v-model.trim="form.uniPay"
                      :min="0"
                      :max="data.amountPay">
                    </a-input-number>
                    RM
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="现金" prop="money">
                    <a-input-number size="large" v-model.trim="form.money" :min="0" :max="9999999999"></a-input-number>
                    RM
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" v-if="data.orderType !==10">
                  <a-form-model-item label="卡余额" prop="balance">
                    <a-input-number size="large" v-model.trim="form.balance" :min="0" :max="data.amountPay"
                                    :disabled="!data.memberId"></a-input-number>
                    RM
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <div class="mt-20">
              <a-alert :message="payInfo" type="info" show-icon v-if="payInfo"/>
              <div v-if="isSmsPay">验证码将发送至手机号: <span class="primary-color">{{data.mobile}}</span></div>
            </div>
          </a-form-model-item>
<!--          <a-form-model-item label="备注" prop="cashRemark" :label-col="{span:4}" :wrapper-col="{span:18}" v-if="payType === 0">-->
<!--            <a-select v-model="form.cashRemark" translate="no">-->
<!--              <a-select-option :value="0">-->
<!--                none-->
<!--              </a-select-option>-->
<!--              <a-select-option :value="1">-->
<!--                <span>Cash</span>-->
<!--              </a-select-option>-->
<!--              <a-select-option :value="2">-->
<!--                <span>Credit Card</span>-->
<!--              </a-select-option>-->
<!--              <a-select-option :value="3">-->
<!--                <span>Thai Transfer</span>-->
<!--              </a-select-option>-->
<!--              <a-select-option :value="4">-->
<!--                <span>Coupon</span>-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="验证码" prop="type" :label-col="{span:4}" :wrapper-col="{span:18}" v-if="isSmsPay">
            <a-row>
              <a-col :span="6">
                <a-input v-model="sms" :maxLength="6" placeholder="请输入验证码" extra="验证码已发送至您的手机，120秒内有效"></a-input>
              </a-col>
              <a-col :span="18">
                <a-button class="ml-20" type="primary" ghost icon="message" @click="handleSendCode" v-show="!sendCode" >发送验证码</a-button>
                <div class="change-phone__countdown" v-show="sendCode">
                  <span class="ml-20">
                    <a-statistic-countdown
                      :value="deadline"
                      @finish="handleFinish">
                    </a-statistic-countdown>
                  </span>
                  <span class="change-phone__countdown--info"> 后重新获取（5分钟内有效）</span>
                </div>
              </a-col>
            </a-row>
            <a-row>
<!--              <div>{{data}}</div>-->
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <div class="pay-modal" v-show="isCanScan">
      <a-spin size="large"></a-spin>
      <div v-if="!isPaying">
        <h1 style="padding: 30px; letter-spacing: 2px;">等待支付，请勿进行其他操作!</h1>
        <a href="javascript:;" @click="handleCancelScan">取消支付</a>
      </div>
      <h1 v-else>扫码成功，等待返回支付结果!</h1>
    </div>
  </a-modal>
</template>

<script>
import { orderpay, haspaying, orderInfo } from '@/api/order';
import { sendsms } from '../api/user';
import { getLocal } from '../libs/utils';
import { releaseCar, updatePrinted } from '@/api/preMatch';

export default {
  name: 'Payment',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: this.value,
      payType: 0,
      isPaying: false,
      isCanScan: false,
      timer: null,
      form: {
        uniPay: 0,
        wxPay: 0,
        aliPay: 0,
        money: 0,
        balance: 0,
        cashRemark: ''
      },
      // 支付类型 0:现金 1:支付宝 2:微信 3 个人账户 9惠商魔方支付
      paymentMap: new Map([
        // [2, '微信'],
        // [1, '支付宝'],
        [0, '现金'],
        [1, 'Credit Card'],
        [2, 'Thai Transfer'],
        [4, 'Coupon'],
        [6, '聚合支付'],
        [3, '卡余额'],
        // [5, '自定义'],
      ]),
      payKeyMap: {
        'uniPay': 6,
        'money': 0,
        'credit': 1,
        'thai': 2,
        'coupon': 4,
        // 'aliPay': 1,
        // 'wxPay': 2,
        'balance': 3,
      },
      confirmLoading: false,
      deadline: null,
      sendCode: false,
      sms: null,
      msgId: null,
    };
  },
  computed: {
    isSmsPay() {
      const storeInfo = getLocal('storeInfo');
      return this.payType === 3 && storeInfo && storeInfo.memberAmountPay === 1;
    },
    payInfo() {
      const result = [];
      if (this.payType !== 5) {
        return this.paymentMap.get(this.payType) + `支付： ${this.data.amountPay} RM`;
      }
      for (let [key, value] of Object.entries(this.form)) {
        const item = this.paymentMap.get(this.payKeyMap[key]);
        value && result.push(item + '：' + (value || 0) + ' RM');
      }
      return result.join('，');
    },
    // 收银
    amountReceipt() {
      if (this.payType !== 5) {
        return this.data.amountPay;
      }

      let amount = 0;
      for (const key in this.form) {
        const money = Number(this.form[key]);
        amount += isNaN(money) ? 0 : money;
      }
      return amount;
    },

    // 待收
    amountUnpaid() {
      const unpaidMoney = this.data.amountPay - this.amountReceipt;
      return unpaidMoney < 0 ? this.data.amountPay : unpaidMoney;
    },

    // 找零
    amountChange() {
      const unpaidMoney = this.amountReceipt - this.data.amountPay;
      return unpaidMoney > 0 ? unpaidMoney : 0;
    },
  },
  created() {},
  mounted() {
    // 数字键
    const NUM_KEYS = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 13];

    const self = this;
    let barCode = '';
    let lastTime = null;
    document.onkeydown = function (e) {
      /*
      * 按键逻辑
      * 当键盘事件被激活的时候，如果是数字键，切和上一次按键间隔小于50毫秒，则进行连接，直到遇到回车键
      * */
      const { keyCode } = e;
      // 可以扫描支付的状态
      if (self.isCanScan && NUM_KEYS.includes(keyCode)) {
        const currentTime = Date.now();
        const intervalTime = 200; // 两次按键间隔事件

        // 判断和上一次的按键间隔
        if (lastTime) {
          if (currentTime - lastTime < intervalTime) {
            // 正常间隔
            if (keyCode === 13) {
              self.handlePay(barCode);
              barCode = '';
              lastTime = null;
            } else {
              barCode += String.fromCharCode(keyCode);
            }
          } else {
            barCode = String.fromCharCode(keyCode);
            lastTime = currentTime;
          }
        } else {
          // 置空
          barCode = String.fromCharCode(keyCode);
          lastTime = currentTime;
        }
      } else {
        barCode = '';
        lastTime = null;
      }
    };

  },
  watch: {
    value(val) {
      this.visible = val;
      console.log('付款界面');
      console.log(this.data);
    },
    data(val) {
      if (val.memberId && val.orderType === 1) {
        // this.payType = 3;
        this.payType = 0;
      }
    }
  },
  methods: {
    handleFinish() {
      this.sendCode = false;
    },
    handleSendCode() {
      const params = {
        mobile: this.data.mobile,
        type: '11000'
      };
      // 调用发送验证码的接口
      sendsms(params).then((data) => {
        this.$message.info('验证码已发送，请向客户询问验证码');
        this.deadline = Date.now() + 1000 * 120; // 120秒
        this.sendCode = true;
        this.msgId = data;
      });
    },
    handleOk() {
      // 收银金额高于应收金额
      // if (this.amountReceipt !== this.data.amountPay && !this.amountChange) {
      if (this.amountReceipt !== this.data.amountPay) {
        this.$message.warning('收银金额与应收金额不符！');
        return;
      }

      if (this.isSmsPay && !this.sms) {
        this.$message.warning('请输入短信验证码');
        return;
      }

      if (this.payType === 6 || (this.payType === 5 && this.form.uniPay)) {
        this.isCanScan = true;
      } else {
        if (this.payType === 0) {
          this.form.cashRemark = 1;
        } else if (this.payType === 1) {
          this.form.cashRemark = 2;
          this.payType = 0;
        } else if (this.payType === 2) {
          this.form.cashRemark = 3;
          this.payType = 0;
        } else if (this.payType === 4) {
          this.form.cashRemark = 4;
          this.payType = 0;
        }
        this.handlePay();
      }
    },

    handlePay(payCode = '') {
      console.log(payCode);
      this.confirmLoading = true;

      const params = {
        orderId: this.data.orderId,
        pays: [],
      };

      // 自定义支付
      if (this.payType === 5) {
        for (const key in this.form) {
          // 如果是微信或则支付宝需要值班编码
          this.form[key] && params.pays.push({
            amountPaid: this.form[key], // 支付金额
            payCode, // 支付编码 微信,支付宝,其他编码
            payMethod: this.payKeyMap[key], // 支付类型 0:现金 1:支付宝 2:微信 3 个人账户 9惠商魔方支付
            remarks: '', // remarks
          });
        }

      } else if (this.isSmsPay) {
        // 余额支付
        params.pays.push({
          amountPaid: this.data.amountPay, // 支付金额
          payCode: this.sms, // 支付编码 微信,支付宝,其他编码
          payMethod: this.payType, // 支付类型 0:现金 1:支付宝 2:微信 3 个人账户 9惠商魔方支付
          remarks: '', // remarks
          msgId: this.msgId
        });
      } else {
        let remark = ''
        if(this.payType === 0){
          switch (this.form.cashRemark) {
            case 1:
              remark = 'Cash'
              break;
            case 2:
              remark = 'Credit Card'
              break;
            case 3:
              remark = 'Thai Transfer'
              break;
            case 4:
              remark = 'Coupon'
              break;
            default:
              remark = ''
              break;
          }
        }
        params.pays.push({
          amountPaid: this.data.amountPay, // 支付金额
          payCode, // 支付编码 微信,支付宝,其他编码
          payMethod: this.payType, // 支付类型 0:现金 1:支付宝 2:微信 3 个人账户 9惠商魔方支付
          remarks: remark, // remarks
        });

      }

      orderpay(params).then((data) => {
        console.log('订单支付');
        console.log(data);
        if (data.orderStatus !== 4) {
          this.isPaying = true;

          const payParams = {
            orderId: data.orderId
          };

          this.second = 5;
          this.timer = setInterval(() => {
            if (this.second === 0) {
              this.handleCancelScan();
              this.isPaying = false;
              clearInterval(this.timer); // 支付失败
              this.$message.error('支付超时，请重新扫码！');
            } else {
              // 调用接口
              haspaying(payParams).then((result) => {
                if (!result) {
                  orderInfo(payParams.orderId).then((res) => {
                    if (res.orderStatus === 4) {
                      this.payOk();
                    }
                  }, (error) => {
                    this.handleCancelScan();
                    this.isPaying = false;
                    clearInterval(this.timer); // 支付失败
                    this.$message.error(`支付失败！${error}`);
                  });
                } else {
                  this.second--;
                }
              });
            }
          }, 5000);
        } else {
          this.payOk();
        }
      }).catch(() => {
        this.confirmLoading = false;
        this.isCanScan = false;
      });
    },

    payOk() {
      if (this.$store.getters.getStoreInfo.diySwitch === 1) {
        console.log('自助机订单付款开始');
        if (this.data.selectedIdList && this.data.selectedIdList.length > 0) {
          console.log('自助机订单付款成功');
          updatePrinted({ orderId: this.data.orderId, idList: this.data.selectedIdList }).then(() => {
            clearInterval(this.timer);
            this.clearSms();
            this.$message.success('支付成功！');
            this.visible = false;
            this.isCanScan = false;
            this.$emit('input', false);
            this.$emit('on-ok');
            this.confirmLoading = false;
          });
        } else {
          console.log('自助机商品订单付款成功');
          clearInterval(this.timer);
          this.clearSms();
          this.$message.success('支付成功！');
          this.visible = false;
          this.isCanScan = false;
          this.$emit('input', false);
          this.$emit('on-ok');
          this.confirmLoading = false;
        }
      } else {
        console.log('非自助机订单付款成功');
        clearInterval(this.timer);
        this.clearSms();
        this.$message.success('支付成功！');
        this.visible = false;
        this.isCanScan = false;
        this.$emit('input', false);
        this.$emit('on-ok');
        this.confirmLoading = false;
      }
    },

    handleCancel() {
      if (this.$store.getters.getStoreInfo.diySwitch === 1) {
        console.log('自助机付款取消');
        // 释放选定座位
        releaseCar({ orderId: this.data.orderId }).then(() => {
          this.clearSms();
          this.visible = false;
          this.$emit('input', false);
          this.$emit('on-cancel');
        });
      } else {
        console.log('非自助机付款取消');
        this.clearSms();
        this.visible = false;
        this.$emit('input', false);
        this.$emit('on-cancel');
      }
    },
    clearSms() {
      this.deadline = null;
      this.sendCode = false;
      this.sms = null;
      this.msgId = null;
      this.payType = 0;
    },
    // 取消扫码
    handleCancelScan() {
      this.confirmLoading = false;
      this.isCanScan = false;
    }
  },
};
</script>

<style lang="less" scoped>
  .pay-modal {
    position: absolute;
    text-align: center;
    background: rgba(255, 255, 255, .8);
    border-radius: 4px;
    padding: 130px 50px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .change-phone {
    margin-bottom: 20px;
    padding: 0 50px;
  }
  .change-phone__countdown {
    display: flex;
  }
  .change-phone__countdown--info {
    padding: 0 5px;
    line-height: 36px;
  }
</style>
